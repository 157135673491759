<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid, handleSubmit }"
    >
      <aom-languages>
        <!-- Resource type -->
        <b-row class="mt-1">
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="resourceType"
              :rules="{ required: true }"
              vid="resourceType"
              name="Resource Type"
            >
              <b-form-group
                label="Resource Type"
                label-for="resourceType"
              >
                <v-select
                  id="resourceType"
                  v-model="selectedType"
                  label="text"
                  :options="resourceTypesSelect"
                  placeholder="Select the Resource Type"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col md="12">
            <!-- File -->
            <template v-if="showFileInput">
              <validation-provider
                ref="addFile"
                :rules="{ required: true }"
                vid="addFile"
                name="File"
              >
                <b-form-group
                  label="Add File (Allowed file extensions .doc, .docx, .docm, .dotx, .txt, .xls, .xlsx, .csv, .xlt, .xlsm, .ppt, .pptx, .pptm, .m4a, .wav, .aac, .mp3, .rar, .zip, .pdf, .png, .jpg, .gif, .bmp, .tif, .mp4, .avi, .mpg)"
                  label-for="addFile"
                >
                  <b-form-file
                    id="addFile"
                    ref="refInputEl"
                    v-model="blogFile"
                    :state="Boolean(blogFile)"
                    placeholder="Add file"
                    :accept="resourceMimeTypes"
                    drop-placeholder="Drop file here..."
                    :aria-invalid="
                      Boolean(blogFile)
                        ? 'true'
                        : 'false'
                    "
                  />
                  <b-form-invalid-feedback
                    :state="Boolean(blogFile)"
                  >
                    {{ "File field is required" }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>

            <!-- Link -->
            <validation-provider
              v-if="showURLInput"
              v-slot="validationContext"
              ref="link"
              :rules="{ required: true, url_with_protocol: true }"
              vid="link"
              name="URL"
            >
              <b-form-group
                label="URL"
                label-for="link"
              >
                <b-form-input
                  id="link"
                  v-model="link"
                  placeholder="https://"
                  :state="getValidationState(validationContext)"
                  :aria-invalid="
                    getValidationState(validationContext)
                      ? 'true'
                      : 'false'
                  "
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Web page -->
            <validation-provider
              v-if="showWebPageInput"
              v-slot="validationContext"
              ref="page_title"
              rules="required|aom_sanitize"
              vid="page_title"
              name="Page Title"
            >
              <b-form-group
                label="Page Title"
                label-for="page_title"
              >
                <b-form-input
                  id="page_title"
                  v-model="page_title"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                  :aria-invalid="
                    getValidationState(validationContext)
                      ? 'true'
                      : 'false'
                  "
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-if="showWebPageInput"
              v-slot="validationContext"
              :ref="`page_content`"
              rules="required"
              vid="page_content"
              name="Page Content"
            >
              <h5>Page Content</h5>
              <aom-text-editor
                v-if="shouldShowTextEditor"
                v-model="page_content"
                :state="getValidationState(validationContext)"
                :height="100"
                :tool-bar="toolBar"
                :show-image="true"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Title -->
        <b-row class="mt-1">
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="title"
              rules="required|aom_sanitize"
              vid="title"
              name="Title"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  placeholder="Title"
                  :state="getValidationState(validationContext)"
                  :aria-invalid="
                    !getValidationState(validationContext)
                      ? 'true'
                      : 'false'
                  "
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Category -->
        <b-row
          v-if="categoriesDisplay && categoriesDisplay.length"
          class="mt-2"
        >
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="category_resource"
              :rules="{ required: true }"
              vid="category_resource"
              name="Category"
            >
              <b-form-group
                label="Category"
                label-for="category_resource"
              >
                <v-select
                  id="category_resource"
                  v-model="selectedCategory"
                  :dir="dir"
                  label="text"
                  :options="categoriesDisplay"
                  placeholder="Select the Categories"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Role -->
        <b-row
          class="mt-2"
        >
          <b-col md="12">
            <user-roles-select
              v-model="selectedUserRole"
              :list-type="userRolesListType"
              :readOnly="isChampionLibrary"
              @update="updateSelectedUserRole"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="!isTraineeSelected && !isProgramTypeTraining"
          class="mt-2"
        >
          <b-col>
            <b-form-group
              label="Visible to public"
              label-for="isPublic"
            >
              <b-form-checkbox
                id="isPublicSwitch"
                v-model="isPublic"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Available -->
        <template v-if="showAvailableSection">
          <b-row class="mt-2">
            <b-col md="3">
              <label class="mt-1">Available</label>
            </b-col>
          </b-row>
          <b-row>
            <!-- When -->
            <b-col
              md="12"
              lg="4"
            >
              <validation-provider
                v-slot="validationContext"
                rules="required"
                vid="show-when"
                name="When"
              >
                <b-form-group label-for="show">
                  <v-select
                    id="show-when"
                    v-model="happenWhen"
                    label="name"
                    placeholder="When"
                    :options="eventTriggersDisplay"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Event -->
            <b-col
              md="12"
              lg="8"
            >
              <validation-provider
                v-slot="validationContext"
                rules="required"
                vid="show-actionable-event"
                name="Event"
              >
                <b-form-group label-for="show">
                  <aom-events-select
                    id="show-actionable-event"
                    v-model="actionableEvent"
                    :options="eventsDisplayComputed"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Custom date -->
              <b-row v-if="showCustomDateField">
                <b-col md="12">
                  <aom-date-picker
                    v-model="scheduledDate"
                    :min="minCustomDate"
                  />
                </b-col>
              </b-row>
            </b-col>

            <!-- Period -->
            <b-col
              v-if="showPeriodField"
              md="12"
              lg="6"
            >
              <validation-provider
                v-slot="validationContext"
                vid="time"
                :rules="{ required: true }"
                name="Period"
              >
                <b-form-group label-for="show">
                  <v-select
                    id="time"
                    v-model="period"
                    label="#"
                    placeholder="Period"
                    :options="periodList"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Duration -->
            <b-col
              v-if="showDurationField"
              md="12"
              lg="6"
            >
              <validation-provider
                v-slot="validationContext"
                vid="duration"
                :rules="{ required: true }"
                name="Duration"
              >
                <b-form-group label-for="show">
                  <v-select
                    id="duration"
                    v-model="duration"
                    label="name"
                    placeholder="Duration"
                    :options="durationUnitsDisplay"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </template>
        <!-- submit and reset -->
        <b-row class="mt-2">
          <b-col cols="12">
            <b-button
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="onReset()"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="invalid"
              @click="handleSubmit(onSubmit)"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              <span v-if="isUpdating"> Updating...</span>
              <span v-else>Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </aom-languages>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormFile,
  BSpinner,
  BFormInvalidFeedback,
  BFormCheckbox
} from "bootstrap-vue";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import UserRolesSelect from "@/@aom-core/UserRolesSelect.vue";
import AomLanguages from "@aom-core/AomLanguages";
import {
  makeSuccessToast,
  makeErrorToast,
  convertModelToFormData,
} from "@/libs/utils";
import resourcesService from "@/services/resourcesService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
//eslint-disable-next-line
import { required, urlWithProtocol } from "@validations";
import {
  resourceTypes,
  resourceMimeTypes,
  resourceTypesSelect,
  actions,
  eventTriggers,
  eventTriggersDisplay,
  actionableEventDisplay as defaultEventsDisplay,
  durationUnitsDisplay,
  userRoles,
  userRolesDisplay,
  programTypes
} from '@models';
import { mapGetters } from "vuex";

import useActionableEvent from "@/views/apps/useActionableEvent.js";
import AomEventsSelect from '@/@aom-core/AomEventsSelect.vue';
import AomDatePicker from "@aom-core/AomDatePicker";
import AomTextEditor from "@aom-core/AoMTextEditor.vue";
import { AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS } from '@/constants/aom-text-editor';

const linkPlaceholder = `${window.location.protocol}//`;

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormFile,
    vSelect,
    UserRolesSelect,
    AomLanguages,
    BSpinner,
    BFormInvalidFeedback,
    AomEventsSelect,
    AomDatePicker,
    ValidationProvider,
    ValidationObserver,
    AomTextEditor,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  props: {
    isProgramResource: {
      type: Boolean,
    },
    isChampionLibrary: {
      type: Boolean
    },
  },
  data() {
    return {
      dir: "ltr",
      blogFile: null,
      title: "",
      page_title: "",
      page_content: "",
      isPublic: false,
      link: linkPlaceholder,
      selectedType: null,
      selectedLanguage: [
        {
          value: 1,
          name: "English",
          abbreviation: "eng",
        },
      ],
      selectedCategory: [],
      selectedUserRole: [],
      isUpdating: false,
      resourceTypes,
      categoriesDisplay: [],
      eventTriggersDisplay,
      defaultEventsDisplay,
      durationUnitsDisplay,
      periodList: Array.from({length: 100}, (_, i) => i + 1),
      shouldShowTextEditor: true,
      toolBar: AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    isProgramTypeTraining() {
      if (this.isProgramResource) {
        return this.defaultProgram?.type_id === programTypes.TRAINING;
      }
      return false;
    },
    eventsDisplayComputed() {
      if (this.isTraineeSelected) {
        return this.eventsDisplayForTraining;
      }
      return this.eventsDisplay;
    },
    isTraineeSelected() {
      return this.selectedUserRole?.find(role => role.id === userRoles.TRAINEE);
    },
    filterOptions() {
      return [{ name: 'Roles'}];
    },
    userRolesListType() {
      if (this.isProgramResource) {
        if (this.defaultProgram?.type_id === programTypes.TRAINING) {
          return 'trainee';
        }
        return 'participant';
      }
      return 'noAdmin';
    },
    showFileInput() {
      return this.selectedType && this.selectedType.value[0] === resourceTypes.FILE;
    },
    showURLInput() {
      return this.selectedType && this.selectedType.value[0] === resourceTypes.URL;
    },
    showWebPageInput() {
      return this.selectedType && this.selectedType.value[0] === resourceTypes.WEB_PAGE;
    },
    showAvailableSection() {
      if (this.selectedUserRole && this.selectedUserRole.length > 0) {
        return !this.selectedUserRole.find(r => r.id === userRoles.CHAMPION);
      }
      return true;
    }
  },
  created() {
    this.fetchResourceCategoryList();

    if (this.isChampionLibrary) {
      this.selectedUserRole = [
        { id:userRoles.CHAMPION, name:userRolesDisplay[userRoles.CHAMPION] },
      ];
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'add-resource-right') {
        this.shouldShowTextEditor = isJustShown;
      }
    });
  },
  watch: {
    selectedUserRole: {
      handler(roles) {
        let isTraineeSelected = roles?.find(role => role.id === userRoles.TRAINEE);
        if (isTraineeSelected) {
          this.hideParticipantAction();
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    hideParticipantAction() {
      if (this.happenWhen?.id !== eventTriggers.ON) {
        this.happenWhen = eventTriggersDisplay.find(event => event.id === eventTriggers.ON);
      }
      this.scheduled_actions = this.getScheduledActions();
      if (this.scheduled_actions[0]?.actionable_event_id) {
        let isKeyDate = this.eventsDisplay.find(event => event.id === this.scheduled_actions[0]?.actionable_event_id)?.isKeyDate;
        if (!isKeyDate) {
          this.actionableEvent = undefined;
        }
      }
    },
    async resolveService() {
      const programId = this.$route.params.id;
      if (this.isProgramTemplate) {
        return groupsService.getListTemplates(programId);
      }
      return groupsService.getList(programId);
    },
    async getGroups() {
      try {
        this.isLoadingGroups = true;
        const response = await this.resolveService();
        const {items} = response.data;
        this.programUserGroups = items.filter(group => !group.is_champion).map(i => ({id: i.id, name: i.name, type: i.type.name}));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Groups list not loaded."));
      } finally {
        this.isLoadingGroups = false;
      }
    },
    getScheduledActions() {
      const scheduledAction = {
        action_id: actions.SHOW
      };

      let newPeriod = 0;
      let newDuration = this.duration?.id;
      // Convert relative_value
      if (this.happenWhen) {
        switch (this.happenWhen.id) {
        case eventTriggers.BEFORE:
          newPeriod = -this.period;
          break;
        case eventTriggers.ON:
          newPeriod = 0;
          newDuration = undefined;
          break;
        case eventTriggers.AFTER:
          newPeriod = this.period;
          break;
        }
      }
      // Assign values to scheduledAction
      // Custom date
      if (this.scheduledDate) {
        scheduledAction.absolute_date = this.scheduledDate;
      } else {
        scheduledAction.actionable_event_id = this.actionableEvent?.id;
      }
      if (newPeriod) {
        scheduledAction.relative_value = newPeriod;
      }
      if (newDuration) {
        scheduledAction.relative_unit_id = newDuration;
      }

      return [scheduledAction];
    },
    async onSubmit() {
      try {
        this.isUpdating = true;

        const translation = {
          locale_id: this.selectedLanguage[0].value,
          title: this.title,
        };
        if (this.selectedType.value[0] === resourceTypes.FILE) {
          translation.file = this.blogFile;
        }
        if (this.selectedType.value[0] === resourceTypes.URL) {
          translation.link = this.link;
        }
        if (this.selectedType.value[0] === resourceTypes.WEB_PAGE) {
          translation.page_title = this.page_title;
          translation.page_content = this.page_content;
        }
        const payload = {
          type_id: this.selectedType.value[0],
          category_id: this.selectedCategory.value,
          roles: this.selectedUserRole.map(role => ({ id: role.id })),
          translations: [translation],
          is_public: this.isPublic? '1': '0'
        };
        // Add scheduled_actions to payload for normal resource
        if (this.showAvailableSection) {
          payload.scheduled_actions = this.getScheduledActions();
        }
        const response = this.isProgramResource
          ? await resourcesService.createProgramResource(
            this.$route.params.id,
            convertModelToFormData(payload)
          )
          : await resourcesService.createLibraryResource(
            convertModelToFormData(payload)
          );
        if (response) {
          this.$emit("resourceAdded", response.data.id);
          this.$toast(makeSuccessToast("Resource created successfully."));
          this.$root.$emit("bv::toggle::collapse", "add-resource-right");
        }
      } catch (e) {
        const { data } = e.response; 
        this.$toast(
          makeErrorToast(data.message ?? "Something went wrong. Resource not saved.")
        );
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    onReset() {
      this.title = "";
      this.link = linkPlaceholder;
      if (!this.isChampionLibrary) {
        this.selectedUserRole = [];
      }
      this.selectedCategory = [];
      this.happenWhen = undefined;
      this.actionableEvent = undefined;
      this.period = undefined;
      this.duration = undefined;
      this.scheduledDate = undefined;
      this.$root.$emit("bv::toggle::collapse", "add-resource-right");
    },
    updateSelectedUserRole(role) {
      this.selectedUserRole = role;
    },
    async fetchResourceCategoryList() {
      try {
        this.isLoading = true;
        const response = this.isProgramResource
          ? await resourcesService.getProgramResourceCategoriesList(this.$route.params.id)
          : await resourcesService.getResourceCategoriesList();
        if (response) {
          this.categoriesDisplay = response.data.items.map(
            resourceCategory => ({
              value: resourceCategory.id,
              text: resourceCategory.name,
            })
          );
        }
      } catch (e) {
        this.$toast(
          makeErrorToast("Something went wrong. Resource category not loaded.")
        );
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  setup(props) {
    const {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      eventsDisplay,
      eventsDisplayForTraining,
      minCustomDate
    } = useActionableEvent(props.isProgramResource);

    return {
      happenWhen,
      actionableEvent,
      period,
      duration,
      scheduledDate,
      showCustomDateField,
      showPeriodField,
      showDurationField,
      getValidationState,
      resourceTypesSelect,
      eventsDisplay,
      resourceMimeTypes,
      minCustomDate,
      eventsDisplayForTraining
    };
  },
};
</script>
